import React, { useState, useMemo } from "react";
import {
  container,
  tabContainer,
  tabs,
  tabButton,
  activeTabCss,
  colorsContainer,
  colorItem,
  hexLabel,
} from "./styles.module.css";

import {
  monochromaticScheme,
  complementaryScheme,
  analogousScheme,
  triadicScheme,
  tetradicScheme,
} from "../../helpers/generateColorsSchemas";
import AdManaWithPlaceholder from "../Ads/AdManaWithPlaceHolder";

// Labels en múltiples idiomas
const TABS_LABELS = {
  en: [
    { label: "Monochromatic", value: "monochromatic" },
    { label: "Complementary", value: "complementary" },
    { label: "Analogous", value: "analogous" },
    { label: "Triadic", value: "triadic" },
    { label: "Tetradic", value: "tetradic" },
  ],
  es: [
    { label: "Monocromático", value: "monochromatic" },
    { label: "Complementario", value: "complementary" },
    { label: "Análogo", value: "analogous" },
    { label: "Tríada", value: "triadic" },
    { label: "Tetrádico", value: "tetradic" },
  ],
  pt: [
    { label: "Monocromático", value: "monochromatic" },
    { label: "Complementar", value: "complementary" },
    { label: "Análogo", value: "analogous" },
    { label: "Tríade", value: "triadic" },
    { label: "Tetrádico", value: "tetradic" },
  ],
  fr: [
    { label: "Monochromatique", value: "monochromatic" },
    { label: "Complémentaire", value: "complementary" },
    { label: "Analogue", value: "analogous" },
    { label: "Triadique", value: "triadic" },
    { label: "Tétradique", value: "tetradic" },
  ],
};

const ColorSchemesTabs = ({
  baseColor = "#2196f3",
  locale = "en",
  heading = "Color Schemes",
}) => {
  const [activeTab, setActiveTab] = useState("monochromatic");

  // Obtiene las tabs según el locale (fallback a 'en')
  const currentTabs = TABS_LABELS[locale] || TABS_LABELS.en;

  // Función para obtener el arreglo de colores según la pestaña activa
  const getSchemeColors = (color, scheme) => {
    switch (scheme) {
      case "monochromatic":
        return monochromaticScheme(color);
      case "complementary":
        return complementaryScheme(color);
      case "analogous":
        return analogousScheme(color);
      case "triadic":
        return triadicScheme(color);
      case "tetradic":
        return tetradicScheme(color);
      default:
        return monochromaticScheme(color);
    }
  };

  // Obtenemos el arreglo de colores del esquema activo
  const colorArray = useMemo(
    () => getSchemeColors(baseColor, activeTab),
    [baseColor, activeTab]
  );

  // Generamos un degradado a partir del esquema monocromático del color base
  const monoColors = useMemo(
    () => monochromaticScheme(baseColor, 5),
    [baseColor]
  );
  const backgroundGradient = `linear-gradient(to bottom right, ${
    monoColors[0]
  }, ${monoColors[monoColors.length - 1]})`;

  return (
    <div className={container} style={{ background: backgroundGradient }}>
      <div className={tabContainer}>
        {/* Heading para la sección */}
        <h1 style={{ margin: "1rem auto", padding: "0", textAlign: "center" }}>
          {heading}
        </h1>
        {/* Barra de pestañas */}
        <div className={tabs}>
          {currentTabs.map(tab => (
            <button
              key={tab.value}
              onClick={() => setActiveTab(tab.value)}
              className={`${tabButton} ${
                activeTab === tab.value ? activeTabCss : ""
              }`}
            >
              {tab.label}
            </button>
          ))}
        </div>

        {/* Lista de colores generados en formato "cuadrado" */}
        <div className={colorsContainer}>
          {colorArray.map(color => (
            <div
              key={color}
              className={colorItem}
              style={{ backgroundColor: color }}
            >
              <span className={hexLabel}>{color}</span>
            </div>
          ))}
        </div>
        <AdManaWithPlaceholder
          path="/22250597679/BloquesWortise/BloquesWortise_Content3"
          size={[
            [300, 250],
            [970, 250],
            [728, 250],
            [970, 90],
            [728, 90],
          ]}
          id="Content3"
        />
      </div>
    </div>
  );
};

export default ColorSchemesTabs;
